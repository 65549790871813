<script setup lang="ts">
import { formatPrice } from "~/helpers/formatters";
import { ShippingMethodSidebar } from "#components";
import type { Schemas } from "#shopware";

const shippingMethodSidebar = ref<InstanceType<typeof ShippingMethodSidebar>>();

const { order } = defineProps<{
	order?: Schemas["Order"];
}>();

const { cartItems, totalPrice: cartTotalPrice, shippingTotal: cartShippingTotal, subtotal: cartSubtotal } = useCart();
const { selectedShippingMethod } = useSessionContext();

const selectedHomeDelivery = computed(() => {
	return selectedShippingMethod.value?.technicalName === "delivery";
});

const subtotal = computed(() => {
	if (order) {
		const orderAmountTotal = order?.amountTotal ?? 0;
		const orderShippingTotal = order?.shippingTotal ?? 0;
		return orderAmountTotal - orderShippingTotal;
	}
	return cartSubtotal.value;
});

const discount = computed(() => {
	if (order) {
		return (
			order.lineItems?.reduce((acc, item) => {
				if (item.type === "promotion") {
					return acc + (item.totalPrice || 0);
				}

				return acc;
			}, 0) || 0
		);
	}
	return (
		cartItems.value?.reduce((acc, item) => {
			if (item.type === "promotion") {
				return acc + (item.price?.totalPrice || 0);
			}

			return acc;
		}, 0) || 0
	);
});

const shippingTotal = computed(() => (order ? order.shippingTotal : cartShippingTotal.value));
const totalPrice = computed(() => (order ? order.amountTotal : cartTotalPrice.value));
</script>

<template>
	<div class="flex flex-col gap-2">
		<div class="flex justify-between text-sm">
			<span class="">Subtotaal</span>
			<span class="">{{ formatPrice(subtotal) }}</span>
		</div>
		<div class="flex justify-between text-sm" v-if="discount < 0 && !order">
			<span class="">Korting</span>
			<span class="">{{ formatPrice(discount) }}</span>
		</div>
		<div class="flex justify-between text-sm">
			<span>
				Verzendkosten<br />
				<KippieLink
					class="text-xs underline"
					@click.prevent="shippingMethodSidebar?.open()"
					to="#"
					v-if="!order && selectedHomeDelivery && cartShippingTotal > 0"
				>
					Toch liever gratis afhalen?
				</KippieLink>
			</span>
			<span class="">{{ formatPrice(shippingTotal) }}</span>
		</div>
		<div class="border-b border-brown w-full"></div>
		<div class="flex justify-between font-bold">
			<span class="">Totaal</span>
			<span class="">{{ formatPrice(totalPrice) }}</span>
		</div>
		<ShippingMethodSidebar ref="shippingMethodSidebar" />
	</div>
</template>
